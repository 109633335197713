<template>
  <div v-loading="loading">
    <ui-card
      :heading="`${$t('actions.edit')} ${$tc('models.department')}`"
      class="mb-8"
    >
      <department-form
        v-if="department"
        :department="department"
        @submit="handleSubmit"
        @cancel="handleCancel"
      ></department-form>
    </ui-card>

    <auth-checker
      :accessRoles="[
        'SuperAdmin',
        'CompanyAdmin',
        'BuildingAdmin',
      ]"
    >
      <ui-card 
        :heading="$t('labels.course.active_course')"
        class="mb-8"
      >

        <template #hright>
          <el-select v-model="selectedBuildingCourse" :placeholder="$t('actions.select')" v-if="notActiveCourses">
            <el-option
              v-for="item in notActiveCourses"
              :key="item.id"
              :label="getTranslation(item.title)"
              :value="item.id">
            </el-option>
          </el-select>
          <ui-link
            type="success"
            size="small"
            uppercase
            @click="addActiveCourse"
            >{{$tc('actions.add')}}</ui-link
          >
        </template>
        <ui-list
          :disableXScroll="true"
          :headings="[$t('fields.name'), '']"
          :items="activeCourses"
          :sort="[{ prop: 'title', type: 'translation' }]"
          v-if="activeCourses !== null && activeCourses.length > 0"
        >
          <template v-slot:default="props">
            
            <ui-list-data 
              :text="getTranslation(props.item.title)"
            />

          
            <ui-list-data small>
              <ui-link
                type="danger"
                size="small"
                uppercase
                @click="acShowDeleteModel(props.item.id)"
                >{{ $t('actions.remove') }}</ui-link
              >
            </ui-list-data>
          </template>
        </ui-list>

        <p v-else>{{$tc('labels.course.no_courses_active')}}</p>
      </ui-card>
    </auth-checker>
    

    <ui-card :heading="`${$t('actions.delete')} ${$tc('models.department')}`">
      <ui-link type="danger" @click="handleDelete">{{
        `${$t('actions.delete')} ${$tc('models.department')}`
      }}</ui-link>
    </ui-card>


    <ui-modal
      :show="showDeleteModal"
      :text="$tc('actions.confirm_delete', { model: $tc('models.department') })"
      :confirmText="$t('actions.delete')"
      @confirm="deleteDepartment"
      @cancel="showDeleteModal = false"
    >
    </ui-modal>

    <ui-modal
      :show="showACDeleteModal"
      :text="$tc('labels.course.active_course_delete')"
      :confirmText="$t('actions.delete')"
      @confirm="deleteActiveCourse(activeCourse.deleteTarget)"
      @cancel="showACDeleteModal = false"
    >
    </ui-modal>
  </div>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import UiLink from '@/components/ui/UiLink';
import UiModal from '@/components/ui/UiModal';
import UiList from '@/components/ui/UiList';
import UiListData from '@/components/ui/UiListData';
import DepartmentForm from '../components/DepartmentForm';
import AuthChecker from '../../auth/components/AuthChecker';
import store from '@/store'

import {getDepartment, updateDepartment, deleteDepartment, getCourse, getActiveCourses, createActiveCourse, deleteActiveCourse, getCoursesByBuildingId} from '../api';

export default {
  data() {
    return {
      department: null,
      activeCourses : [],
      buildingCourses: null,
      selectedBuildingCourse: null,
      loading: false,
      showDeleteModal: false,
      showACDeleteModal: false,

      
      activeCourse: {
        deleteTarget: null,
      },

      userRole: null,
    };
  },

  components: {
    UiCard,
    UiLink,
    UiModal,
    UiList,
    UiListData,
    DepartmentForm,
    AuthChecker
  },

  computed: {
    notActiveCourses: function() {
      if (this.activeCourses !== null && this.activeCourses !== [] && this.activeCourses !== "" && this.buildingCourses !== null) {
        var activeCourseIds = this.activeCourses.map(e => e.id);
        return this.buildingCourses.filter(e => !activeCourseIds.includes(e.id))
      } else return null;
    }
  },

  methods: {
    async getDepartment() {
      this.loading = true;
      const department = await getDepartment(this.$route.params.id);
      this.department = department;
      await this.getCoursesByBuildingId(department.buildingId);
      this.loading = false;
    },

    async getActiveCourses() {
      this.activeCourses = await getActiveCourses(this.$route.params.id);
    },

    async deleteActiveCourse(courseId) {
      const departmentId = parseInt(this.$route.params.id);
      await deleteActiveCourse(departmentId, courseId);
      this.showACDeleteModal = false;

      this.getActiveCourses();
      this.getCoursesByBuildingId(this.department.buildingId);
    },

    async getCoursesByBuildingId(buildingId) {
      this.buildingCourses = await getCoursesByBuildingId(buildingId);
    },

    async handleSubmit() {
      this.loading = true;
      await updateDepartment(this.department);
      this.$router.back();
      this.loading = false;
    },

    handleDelete() {
      this.showDeleteModal = true;
    },

    addActiveCourse() {
      const departmentId = parseInt(this.$route.params.id);

      if (this.selectedBuildingCourse !== null) {
        createActiveCourse(departmentId, this.selectedBuildingCourse)
        this.getActiveCourses();
        this.getCoursesByBuildingId(this.department.buildingId);
      }
    },

    async deleteDepartment() {
      this.loading = true;
      await deleteDepartment(this.department.id);
      this.showDeleteModal = false;
      this.$router.back();
      this.loading = false;
    },

    handleCancel() {
      this.$router.back();
    },

    getTranslation(transList) {
      const trans = transList.filter(t => t.lang === this.$i18n.locale);
      if (trans.length == 0) return '';
      else return trans[0].value;
    },

    acShowDeleteModel(courseId) {
      this.activeCourse.deleteTarget = courseId;
      this.showACDeleteModal = true;
    },
  },

  created() {
    this.userRole = store.state.oidcStore.user.role;
    this.getDepartment();
    this.getActiveCourses();
  },

  async mounted() {
    let department = await getDepartment(this.$route.params.id);
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.departments", link: "/app/departments/"},
      {name: department.name, link: "/app/departments/" + department.id + "/edit"},
      {name: "actions.edit", link: "/app/departments/" + department.id + "/edit"}
    ]);
  },
};
</script>
